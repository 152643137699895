import React from 'react';

const LoginPage = () => {
  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4">NoteBook: A Better World</h1>
    </div>
  );
};

export default LoginPage;

import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import TodoList from './TodoList'; // Import the TodoList component
import Sidebar from 'react-sidebar';

const Dashboard = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const onSetSidebarOpen = (open) => {
    setSidebarOpen(open);
  };

  return (
    <Sidebar
      sidebar={
        <div>
          {/* Sidebar content goes here */}
            <p> Coaching </p>
          <p> WorkOut </p>
        </div>
      }
      open={sidebarOpen}
      onSetOpen={onSetSidebarOpen}
      styles={{ sidebar: { background: 'white' } }}
    >
      <div className="wrapper">
        {/* Navbar */}
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          {/* Add a button to toggle the sidebar */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={() => onSetSidebarOpen(true)}
          >
            ☰
          </button>
          <a className="navbar-brand" href="#">Notebook</a>
        </nav>

        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          {/* ... (Content Header code remains unchanged) */}

          {/* Main content */}
          <div className="content">
            <div className="container-fluid">
              {/* Include TodoList component here */}
              <TodoList />
            </div>
            {/* /.container-fluid */}
          </div>
          {/* /.content */}
        </div>
        {/* /.content-wrapper */}
      </div>
    </Sidebar>
  );
};

export default Dashboard;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css';

const TodoList = () => {
  const [todos, setTodos] = useState([]);
  const [newTodo, setNewTodo] = useState('');
  const [editTodo, setEditTodo] = useState(null);
  const [editedTodoText, setEditedTodoText] = useState('');

  useEffect(() => {
    axios
      .get('https://pincore.pt/todos')
      .then((response) => setTodos(response.data))
      .catch((error) => console.error('Error fetching todos: ', error));
  }, []);

  const addTodo = () => {
    if (newTodo.trim() === '') return; // Don't add empty todos

    axios
      .post('https://pincore.pt/todos', { description: newTodo })
      .then((response) => setTodos([...todos, response.data]))
      .catch((error) => console.error('Error adding todo: ', error));
    setNewTodo('');
  };

  const removeTodo = (id) => {
    axios
      .delete(`https://pincore.pt/todos/${id}`)
      .then(() => setTodos(todos.filter((todo) => todo.id !== id)))
      .catch((error) => console.error('Error deleting todo: ', error));
  };

  const startEditing = (id, description) => {
    setEditTodo(id);
    setEditedTodoText(description);
  };

  const saveEditedTodo = (id) => {
    axios
      .put(`https://pincore.pt/todos/${id}`, { description: editedTodoText })
      .then((response) =>
        setTodos(todos.map((todo) => (todo.id === id ? response.data : todo)))
      )
      .catch((error) => console.error('Error updating todo: ', error));

    setEditTodo(null);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      addTodo();
    }
  };

  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4">NoteBook: A Better World</h1>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Description</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {todos.map((todo) => (
            <tr key={todo.id}>
              <td>
                {editTodo === todo.id ? (
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      value={editedTodoText}
                      onChange={(e) => setEditedTodoText(e.target.value)}
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-success"
                        onClick={() => saveEditedTodo(todo.id)}
                      >
                        <i className="fas fa-check"></i>
                      </button>
                    </div>
                  </div>
                ) : (
                  <span
                    className={todo.completed ? 'completed' : ''}
                    onClick={() => startEditing(todo.id, todo.description)}
                  >
                    {todo.description}
                  </span>
                )}
              </td>
              <td>
                <button
                  className="btn btn-danger mr-2"
                  onClick={() => removeTodo(todo.id)}
                >
                  <i className="fas fa-trash-alt"></i>
                </button>
                {editTodo !== todo.id && (
                  <button
                    className="btn btn-warning"
                    onClick={() => startEditing(todo.id, todo.description)}
                  >
                    <i className="fas fa-edit"></i>
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="write please..."
          value={newTodo}
          onChange={(e) => setNewTodo(e.target.value)}
          onKeyDown={handleKeyPress} // Handle Enter key press
        />
        <div className="input-group-append">
          <button
            className="btn btn-success"
            type="button"
            onClick={addTodo}
          >
            <i className="fas fa-plus"></i>
          </button>
        </div>
      </div>
    </div>
  );
}

export default TodoList;


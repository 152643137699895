import React from 'react';

const ErrorPage = () => {
  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4">NoteBook: A Better World</h1>
    </div>
  );
};

export default ErrorPage;


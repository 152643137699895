// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: 'Arial', sans-serif;
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-wrap: wrap;
  overflow-y: auto;
}

.table {
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  margin: 10px auto;
  padding: 10px;
  table-layout: fixed;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 0.6rem;
  margin: 0.2rem;
  text-align: center;
  border: 1px solid #e0e0e0;
}

.table .btn {
  padding: 0.4rem;
  margin: 0.2rem;
  cursor: pointer;
  font-size: 0.6rem;
}

.input-group {
  margin-bottom: 1rem;
}

.input-group-append {
  display: flex;
  align-items: center;
}

/* Responsive Font Size */
@media (max-width: 768px) {
  body {
    font-size: 14px;
  }
}

.table thead th {
  background-color: #4285f4;
  color: white;
}

.table .actions {
  display: flex;
  justify-content: flex-end;
}

.table th,
.table td {
  text-align: center;
  position: relative;
}

.table th:first-child,
.table td:first-child {
  width: 82%;
}

.table th:last-child,
.table td:last-child {
  width: 18%;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,yBAAyB;EACzB,SAAS;EACT,UAAU;EACV,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,WAAW;EACX,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;;EAEE,eAAe;EACf,cAAc;EACd,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,cAAc;EACd,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA,yBAAyB;AACzB;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,yBAAyB;AAC3B;;AAEA;;EAEE,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;;EAEE,UAAU;AACZ;;AAEA;;EAEE,UAAU;AACZ","sourcesContent":["body {\n  font-family: 'Arial', sans-serif;\n  background-color: #f5f5f5;\n  margin: 0;\n  padding: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  flex-wrap: wrap;\n  overflow-y: auto;\n}\n\n.table {\n  border-radius: 10px;\n  overflow: hidden;\n  width: 100%;\n  margin: 10px auto;\n  padding: 10px;\n  table-layout: fixed;\n  border-collapse: collapse;\n}\n\n.table th,\n.table td {\n  padding: 0.6rem;\n  margin: 0.2rem;\n  text-align: center;\n  border: 1px solid #e0e0e0;\n}\n\n.table .btn {\n  padding: 0.4rem;\n  margin: 0.2rem;\n  cursor: pointer;\n  font-size: 0.6rem;\n}\n\n.input-group {\n  margin-bottom: 1rem;\n}\n\n.input-group-append {\n  display: flex;\n  align-items: center;\n}\n\n/* Responsive Font Size */\n@media (max-width: 768px) {\n  body {\n    font-size: 14px;\n  }\n}\n\n.table thead th {\n  background-color: #4285f4;\n  color: white;\n}\n\n.table .actions {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.table th,\n.table td {\n  text-align: center;\n  position: relative;\n}\n\n.table th:first-child,\n.table td:first-child {\n  width: 82%;\n}\n\n.table th:last-child,\n.table td:last-child {\n  width: 18%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

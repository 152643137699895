import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const LoginPage = () => {
  return (
    <div className="container d-flex align-items-center justify-content-center min-vh-100">
      <div className="login-container">
        <h2 className="text-center mb-4">Welcome To Pincore</h2>
        <form>
          <div className="mb-3">
            <label htmlFor="username" className="form-label">Username:</label>
            <input type="text" className="form-control" id="username" placeholder="Enter your username" />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">Password:</label>
            <input type="password" className="form-control" id="password" placeholder="Enter your password" />
          </div>
          <button type="button" className="btn btn-primary btn-block">Login</button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;

